





























































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Inject } from '../../../../../support/plugins/front-core'
import { AuthControlServiceType, IAuthControl } from '../../../contracts'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus,
  ResourceActionFailed
} from '@movecloser/front-core'
import { UserModel } from '../../../shared'
import { IProfileService, ProfileServiceType } from '../../../../profile/contracts'
import { ISiteService, SiteServiceType } from '../../../../../contexts'
import { Form } from '../../../../shared/molecules/Form'
import { signupValidationsMap } from '../SignupForm.helpers'
import { SignupFormFormData } from '../SignupForm.contracts'
import { ConnectorErrors, logger } from '../../../../../support'
import { FormErrorsMixin, StructureConfigurable } from '../../../../../support/mixins'
import { DeliveryForm } from '../partials/DeliveryForm'
import { REGISTER_FORM_COMPONENT_KEY, defaultComponentConfig } from '../SignupForm.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SignupGeneral>({
  name: 'SignupGeneral',
  components: {
    Form,
    DeliveryForm
  },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()

    this.config = this.getComponentConfig(REGISTER_FORM_COMPONENT_KEY, defaultComponentConfig)
  }
})
export class SignupGeneral extends Mixins(FormErrorsMixin, StructureConfigurable) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: String, required: false, default: null })
  public readonly buttonIcon!: string | null

  @Prop({ type: Object, required: true })
  public socialsConfig!: AnyObject

  public error: string | null = null
  public pages: Record<string, string> | null = null
  public socialPending: boolean = false

  public validatorsMap = signupValidationsMap

  /**
   * Default values for payload.
   */
  public payload: SignupFormFormData = {
    acceptPrivacy: false,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    subscribeToNews: false,
    withDeliveryAddress: false
  } as SignupFormFormData

  public get hasUserDeliveryAddressInput () {
    return this.getConfigProperty('hasUserDeliveryAddressInput')
  }

  public get isSubscribedToNews () {
    return this.getConfigProperty('isSubscribedToNews')
  }

  // public get isSocialLoginAvailable (): boolean {
  //   return typeof this.authControl.socialLogin === 'function'
  // }

  /**
   * Callback to sign with facebook.
   * TODO: After launch
   */
  // eslint-disable-next-line
  public async socialLogin (service: string): Promise<void> {
    //   if (typeof this.authControl.socialLogin !== 'function') {
    //     return
    //   }
    //
    //   try {
    //     this.cleanError()
    //     this.socialPending = true
    //
    //     const userSession = await this.authControl.socialLogin(service)
    //
    //     this.authService.setToken(userSession)
    //     this.authService.setToken(userSession)
    //   } catch (error) {
    //     this.setError(error as Error)
    //   } finally {
    //     this.socialPending = false
    //   }
  }

  /**
   * Handles @success event of `Form`
   */
  public onFail (error: Error): void {
    this.setError(error)
  }

  /**
   * Handles @success event of `Form`
   */
  public onSuccess (): void {
    this.$emit('onSuccess')
  }

  /**
   * Process signup with given payload.
   */
  public async signup (): Promise<void> {
    this.cleanError()
    // debugger
    try {
      const tokenModel = await this.authControl.signup(this.payload)

      this.authService.setToken(tokenModel.toAuthToken())

      if (this.payload.subscribeToNews) {
        this.eventBus.emit('app:newsletter.subscribe', {
          email: this.payload.email, name: ''
        })
      }

      this.eventBus.emit(
        'app:authorization.sign_up',
        {
          email: this.payload.email,
          firstName: this.payload.firstName,
          lastName: this.payload.lastName,
          emailOffers: this.payload.subscribeToNews,
          smsOffers: this.payload.smsAgreement
        }
      )

      if (this.payload.withDeliveryAddress) {
        await this.profileService.storeDeliveryAddress(this.payload)
      }
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

  /**
   * Cleans the error
   */
  private cleanError (): void {
    this.error = null
  }

  protected setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.error = error.message
  }
}

export default SignupGeneral
