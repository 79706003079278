







import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { Country } from '../../../../../../contexts'
import {
  DictionaryServiceType,
  IDictionaryService
} from '../../../../../shared/services/dictionary'
import { Inject } from '../../../../../../support'

import {
  ChangeAddressFormFields,
  ChangeAddressFormFieldsData
} from '../../../../../profile/molecules/ChangeAddressFormFields'
import {
  translateCountryToCountrySelectOption
} from '../../../../../profile/organisms/ChangeAddressForm/ChangeAddressForm.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<DeliveryForm>({
  name: 'DeliveryForm',
  components: { ChangeAddressFormFields },
  created () {
    this.initFormData()
  },
  beforeDestroy (): void {
    this.clearFormData()
  }
})
export class DeliveryForm extends Vue {
  @Prop({ type: Object, required: false })
  public readonly errors!: Record<string, string[]>

  @PropSync('formData', { type: Object, required: true })
  protected model!: ChangeAddressFormFieldsData

  @Inject(DictionaryServiceType)
  protected readonly dictionaryService!: IDictionaryService

  public showFormFields: boolean = false

  public get countries (): Array<Country> {
    return translateCountryToCountrySelectOption(this.dictionaryService.countries)
  }

  public onErrors (): void {
    this.$emit('update:errors')
  }

  protected initFormData (): void {
    this.model = {
      ...this.model,
      ...{
        company: '',
        city: '',
        countryCode: '',
        phoneNumber: '',
        postalCode: '',
        street: ''
      }
    }

    this.$nextTick(() => {
      this.showFormFields = true
    })
  }

  protected clearFormData (): void {
    this.model = {
      ...this.model,
      ...{
        company: '',
        city: '',
        countryCode: '',
        phoneNumber: '',
        postalCode: '',
        street: ''
      }
    }
  }
}

export default DeliveryForm
